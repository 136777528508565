/**
 *
 * Custom function
 *
 */ 

(function($) {

	function scrollMobile() {
		var header = $('#js-header'),
			menu = $('#js-fixed-menu');

		if ( $('body').is('.is-mobile') ) {
			var headerH = header.outerHeight(),
				menuH = menu.innerHeight(),
				scrollValue = headerH - menuH;
			
			$(window).scroll(function() {
				scrolltop = $(window).scrollTop();

				if (scrolltop >= scrollValue) {
					menu.addClass("scroll");
				} else {
					menu.removeClass("scroll");
				}
			});
		}
	}

	function filterElements() {
		var filterSelect = '.select-offers',
			container = '.mix-filter-support';

		if ( $(container).length ) {
			var mixer = mixitup(container);

			$(filterSelect).on('change', function(){
				mixer.filter(this.value);
			});
		}
	}

	/*
	 *	Funzione per l'apertura di una finestra modale tramite cookie
	 */

	function openModal() {
		if (!Cookies.get("openmodal")) {
			$('#onloadmodal').modal();
			Cookies.set("openmodal", 'no', {
				path: '/'
			});
		}
	}

	$(document).ready(function() {

		scrollMobile();
		if ( $('body').is('.is-mobile') ) {
			filterElements();
		}

		// Taglio descrizione feedback (5 righe)
		$('.feedback-list .item .description').fewlines({
			lines: 5,
			openMark: ' [ + ]',
			closeMark: '[ - ]'
		});

		// Gestione età bambini
		$('#bambini').on('change', function(){
			var wrapper = $('.age-wrapper');
			var nChildren = $(this).val();
			var maxChildAge = parseInt( $('#form-contatti').data('max-children-age') );

			wrapper.children('select').remove();

			for (var i = 1; i <= nChildren; i++) {
				var selAge = $('<select name="eta_bambini[' + i + ']"></select>');
				
				for (var e = 0; e <= maxChildAge; e++) {
					if (e === 0) {
						$('<option value="' + e + '">&lsaquo; 1</option>').appendTo(selAge);
					} else {
						$('<option value="' + e + '">' + e + '</option>').appendTo(selAge);
					}
				}

				wrapper.append(selAge);
			}

			if (nChildren > 0) {
				wrapper.show();
			} else {
				wrapper.hide();
			}
		})

		if ($('html').is(':lang(it-IT)')) {
			var testo = 'La informiamo che, "SOC. ROSSONERA DI FABBRI & RINALDI S.N.C. – HOTEL CLITUNNO", in qualità di titolare del trattamento, per il corretto funzionamento delle pagine web e per migliorare la Sua esperienza di navigazione su questo sito, utilizza cookie tecnici e analitici anche di terze parti. Per saperne di più, o per gestire/negare i consensi ai cookies, consulta la pagina <a href="' + url.linkCookie + '">"Cookie policy"</a> e <a href="' + url.linkPrivacy + '">"Privacy Policy"</a>.<br><br>Cliccando su "HO CAPITO" o continuando la navigazione, dichiara di avere compreso le modalità nell\'informativa Estesa ai sensi dell\'Art 13 del Reg. (UE) 2016/679.&nbsp;&nbsp;&nbsp;<a class=\"cookie-close\" href=\"javascript: destroyCookieSection(); scriviCookie();\">HO CAPITO</a>';

			$('.cookie-info div').html(testo);
		}

		$('.newsletter-checkbox small').html('<a href="' + url.linkPrivacyNewsletter + '" target="_blank">' + url.textPrivacyNewsletter  + '</a>' );

	});


	$(window).load(function() {

		//openModal();

		// Aggiustamento per testata fissa
		if ( $('body').is('.is-mobile') ) {
			$('#page').css( 'margin-top', $('.menu-fixed-scroll').outerHeight() );
		} else {
			$('#page').css( 'margin-top', $('.header-top').outerHeight() );
		}

		$('.offert-list .item').equalHeights();

	});

	$(window).resize(function() {


	});

})(jQuery);
